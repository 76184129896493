import { event as sendGAEvent } from 'nextjs-google-analytics'

export enum PromptSource {
  TYPED = 'typedPrompt',
  EDITED = 'editedPrompt',
  EXAMPLE = 'promptExample',
}

interface SendPrompt {
  event: 'Send_Prompt'
  data: {
    prompt_source: PromptSource
    prompt_number: number
  }
}

interface ClickNewChat {
  event: 'Click_New_Chat'
  data?: null
}

interface ClickProduct {
  event: 'Click_Product'
  data: {
    product_name: string
    destination_url: string
  }
}

interface ClickBackArrow {
  event: 'Click_Back_Arrow'
  data: { destination_url: string }
}

interface ClickCustomerCare {
  event: 'Click_Customer_Care'
  data: Record<string, unknown>
}

interface ClickPrivacyPolicy {
  event: 'Click_Privacy_Policy'
  data: {
    destination_url: string
  }
}

interface ClickSensitiveData {
  event: 'Click_Sensitive_Data'
  data?: null
}

interface WritingMessage {
  event: 'Write_Prompt'
  data?: null
}

interface ClickTypebox {
  event: 'Click_Typebox'
  data?: null
}

interface ClickPromptExample {
  event: 'Click_Prompt_Example'
  data?: null
}

type Event =
  | SendPrompt
  | ClickNewChat
  | ClickProduct
  | ClickBackArrow
  | ClickCustomerCare
  | ClickPrivacyPolicy
  | ClickSensitiveData
  | WritingMessage
  | ClickTypebox
  | ClickPromptExample

/**
 * Send an event to GA
 */
export const sendEvent = ({ event, data }: Event) => {
  if (process.env.NEXT_PUBLIC_CURRENT_ENV === 'development') {
    console.log('Sending event', event, data)

    return
  }

  if (process.env.NEXT_PUBLIC_CURRENT_ENV === 'staging') {
    console.log('Sending event', event, data)
  }

  sendGAEvent(event, data || {})
}
