import { IA_CONTAINER_MESSAGE_CLASSNAME } from '@/constants'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react'

type ScrollContext = {
  shouldDisplayArrow: boolean
  isLoading: boolean
  containerRef: React.RefObject<HTMLDivElement>
  messageContainerRef: React.RefObject<HTMLDivElement>
  privacyContainerRef: React.RefObject<HTMLDivElement>
  setShouldDisplayArrow: Dispatch<SetStateAction<boolean>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
  handleScrollDown: () => void
}

const isDiv = (
  target: Node | Element | undefined,
): target is HTMLDivElement => {
  return target?.nodeName === 'DIV'
}

export const ScrollContext = createContext<ScrollContext>({
  containerRef: { current: null },
  isLoading: false,
  messageContainerRef: { current: null },
  privacyContainerRef: { current: null },
  setShouldDisplayArrow: () => undefined,
  setIsLoading: () => undefined,
  shouldDisplayArrow: false,
  handleScrollDown: () => undefined,
})

const NAVIGATION_HEADER_HEIGHT = 80
const SHADOW_BUFFER_HEIGHT = 25
const INPUT_MESSAGE_CONTAINER = 70

export const ScrollProvider = ({ children }: PropsWithChildren) => {
  const messageContainerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const privacyContainerRef = useRef<HTMLDivElement>(null)
  const privacyContainerHeight = useRef(0)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldDisplayArrow, setShouldDisplayArrow] = useState(false)

  /** Listing the privacy resize to get the correct height depends on its state. */
  useEffect(() => {
    const privacyContainer = privacyContainerRef.current
    const resizeObserver = new ResizeObserver(([entry]) => {
      privacyContainerHeight.current = entry.contentRect.height
    })
    if (privacyContainer) {
      resizeObserver.observe(privacyContainer)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    const messageContainer = messageContainerRef.current
    const resizeObserver = new ResizeObserver(([entry]) => {
      const container = containerRef.current
      window.setTimeout(() => {
        if (container) {
          const heightBuffer =
            NAVIGATION_HEADER_HEIGHT +
            privacyContainerHeight.current +
            SHADOW_BUFFER_HEIGHT

          const scrollHeight = container.scrollHeight
          const clientHeight = container.clientHeight
          const scrollValue = scrollHeight - clientHeight
          const target = entry.target

          if (isDiv(target)) {
            const diff =
              scrollValue <= 0
                ? target.offsetTop - scrollValue
                : target.offsetTop - heightBuffer - scrollValue

            const overflow = Math.sign(diff) === -1 ? Math.abs(diff) : 0

            if (overflow) {
              setShouldDisplayArrow(true)
            } else {
              setShouldDisplayArrow(false)
            }
            container.scrollTo({
              top: scrollValue - overflow,
              behavior: 'smooth',
            })
          }
        }
      }, 100)
    })
    const observer = new MutationObserver(() => {
      if (messageContainer) {
        const arrayOfChildren = Array.from(messageContainer.children).reverse()
        const lastIaContainer = arrayOfChildren.find(
          (child) => child.classList.value === IA_CONTAINER_MESSAGE_CLASSNAME,
        )
        if (lastIaContainer) {
          for (const child of lastIaContainer.children) {
            resizeObserver.observe(child)
          }
        }
      }
    })
    if (messageContainer) {
      observer.observe(messageContainer, {
        childList: true,
      })
    }
    return () => {
      resizeObserver.disconnect()
      observer.disconnect()
    }
  }, [])

  const handleScrollDown = () => {
    const container = containerRef.current
    if (container) {
      window.setTimeout(() => {
        const scrollHeight = container.scrollHeight
        const clientHeight = container.clientHeight
        const scrollValue = scrollHeight - clientHeight
        container.scrollTo({ top: scrollValue, behavior: 'smooth' })
        setShouldDisplayArrow(false)
      }, 100)
    }
  }

  /** create listener for scroll purpose when user is scrolling old messages */
  useEffect(() => {
    const container = containerRef.current
    const scrollBehavior = () => {
      if (container && !isLoading) {
        const scrollHeight = container.scrollHeight
        const scrollTop = container.scrollTop
        const viewBox = window.innerHeight - INPUT_MESSAGE_CONTAINER
        if (scrollHeight - scrollTop > viewBox) {
          setShouldDisplayArrow(true)
        } else {
          setShouldDisplayArrow(false)
        }
      }
    }
    if (container) {
      container.addEventListener('scroll', scrollBehavior)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', scrollBehavior)
      }
    }
  }, [isLoading])

  return (
    <ScrollContext.Provider
      value={{
        containerRef,
        privacyContainerRef,
        handleScrollDown,
        setIsLoading,
        isLoading,
        messageContainerRef,
        setShouldDisplayArrow,
        shouldDisplayArrow,
      }}
    >
      {children}
    </ScrollContext.Provider>
  )
}
