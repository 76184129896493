import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'

export const Meta = () => {
  const { t } = useTranslation()

  return (
    <Head>
      <title>{t('common:tab_title')}</title>
      <meta name="title" content={t('common:meta_title')} />
      <meta name="description" content={t('common:meta_description')} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.png" />
      <meta name="og:title" content={t('common:og_title')} />
      <meta name="og:description" content={t('common:og_description')} />
      <meta
        name="og:image"
        content="https://www.bottegaveneta.com/on/demandware.static/Sites-BV-R-WEUR-Site/-/default/dw2a8e6e42/images/thumbnail-social.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={t('common:meta_site_name')} />
      <meta name="twitter:description" content={t('common:meta_description')} />
      <meta property="og:site_name" content={t('common:meta_site_name')} />
      <meta
        name="twitter:image"
        content="https://www.bottegaveneta.com/on/demandware.static/Sites-BV-R-WEUR-Site/-/default/dw2a8e6e42/images/thumbnail-social.jpg"
      />
    </Head>
  )
}
