
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import I18nProvider from 'next-translate/I18nProvider';
import { useEffect } from 'react';
import { Meta } from '@/components/Meta';
import { Tracking } from '@/components/Tracking/Tracking';
import common from '../locales/en/common.json';
import localFont from 'next/font/local';
import { ScrollProvider } from '@/providers/ScrollProvider/ScrollProvider';
import { ChatProvider } from '@/lib/chat/ChatProvider';
const myFont = localFont({ src: './fonts/bottegaveneta-regular.woff2' });
function App({ Component, pageProps }: AppProps) {
    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    return (<>
      <Tracking />
      <I18nProvider lang={'cs'} namespaces={{
            common,
        }}>
        <Meta />
        <ScrollProvider>
          <ChatProvider>
            <Component {...pageProps} className={myFont.className}/>
          </ChatProvider>
        </ScrollProvider>
      </I18nProvider>
    </>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  