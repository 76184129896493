import { RecommendationMessage } from '../openai/types'
import { Chat, TextMessage } from './types'

export const addUserMessageToChat = (chat: Chat, message: string): Chat => {
  const uneditableChat = chat.messages.map((possiblyEditableMessage) => {
    if (possiblyEditableMessage.type === 'text') {
      possiblyEditableMessage.editable = false
    }

    return possiblyEditableMessage
  })

  return {
    messages: [
      ...uneditableChat,
      { type: 'text', sender: 'User', text: message, editable: true },
    ],
    previousProductsIds: chat.previousProductsIds,
  }
}

export const addAIMessageToChat = (chat: Chat, message: string): Chat => {
  return {
    messages: [
      ...chat.messages,
      { type: 'text', sender: 'AI', text: message, editable: false },
    ],
    previousProductsIds: chat.previousProductsIds,
  }
}

export const addAIMessageChunkToChat = (
  chat: Chat,
  messageChunk: string,
): Chat => {
  const newChat = {
    ...chat,
  }

  const aiMessage =
    newChat.messages[newChat.messages.length - 1].type === 'text'
      ? (newChat.messages[newChat.messages.length - 1] as TextMessage)
      : (newChat.messages[newChat.messages.length - 2] as TextMessage)

  aiMessage.text += messageChunk

  return newChat
}

export const addRecommendationMessageToChat = (
  chat: Chat,
  recommendation_message: RecommendationMessage,
): Chat => {
  const products_ids = recommendation_message.product_list.map(
    (product) => product.id,
  )

  const recommendation = recommendation_message.product_list.map((product) => ({
    title: product.title,
    description: product.description,
    imageUrl: product.image,
    url: product.url,
  }))

  return {
    messages: [
      ...chat.messages,
      { type: 'recommendation', sender: 'AI', recommendation },
    ],
    previousProductsIds: [...chat.previousProductsIds, ...products_ids],
  }
}

export const getTextMessagesFromChat = (chat: Chat): Chat => {
  return {
    messages: chat.messages
      .filter((message) => message.type === 'text')
      .slice(-10),
    previousProductsIds: chat.previousProductsIds,
  }
}

export const initializeChat = (intialAIMessage: string): Chat => {
  return {
    messages: [
      {
        type: 'text',
        sender: 'AI',
        text: intialAIMessage,
        editable: false,
      },
    ],
    previousProductsIds: [],
  }
}

export const getChatUserLength = (chat: Chat): number => {
  return chat.messages.filter((message) => message.sender === 'User').length
}

export const updateMessageToChat = (chat: Chat, message: string): Chat => {
  const updatedMessageIndex = chat.messages.findIndex(
    (mess) => mess.type === 'text' && mess.editable,
  )

  const updatedChat = chat.messages.slice(0, updatedMessageIndex)

  return {
    messages: [
      ...updatedChat,
      { type: 'text', sender: 'User', text: message, editable: true },
    ],
    previousProductsIds: chat.previousProductsIds,
  }
}
