import { useEffect, useState } from 'react'

export const useChatId = () => {
  const [token, setToken] = useState<null | string>(null)
  useEffect(() => {
    const fetchToken = async () => {
      const res = await fetch('/api/chat-id')
      const token = await res.text()
      setToken(token)
    }

    fetchToken().catch((err) => {
      console.log(err)
    })
  }, [])
  return token
}
