import { consent, GoogleAnalytics } from 'nextjs-google-analytics'
import { useEffect } from 'react'
import { OneTrust } from './OneTrust'

const updateConsent = () => {
  const shouldTrack = window.OnetrustActiveGroups.includes('C0002')

  consent({
    arg: 'update',
    params: shouldTrack
      ? { ad_storage: 'granted', analytics_storage: 'granted' }
      : { ad_storage: 'denied', analytics_storage: 'denied' },
  })
}

export const Tracking = () => {
  useEffect(() => {
    window.OptanonWrapper = () => {
      updateConsent()

      window.OneTrust.OnConsentChanged(() => {
        updateConsent()
      })
    }
  }, [])

  return (
    <>
      <GoogleAnalytics trackPageViews defaultConsent="granted" />
      <OneTrust />
    </>
  )
}
