import useTranslation from 'next-translate/useTranslation'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  addAIMessageChunkToChat,
  addAIMessageToChat,
  addRecommendationMessageToChat,
  addUserMessageToChat,
  getChatUserLength,
  getTextMessagesFromChat,
  initializeChat,
  updateMessageToChat,
} from './chat'
import { Chat, TextMessage } from './types'
import { useCSRFToken } from '../csrf/useCSRFToken'
import { ScrollContext } from '@/providers/ScrollProvider/ScrollProvider'
import { PromptSource, sendEvent } from '../tracking/events'
import {
  EventSourceParser,
  ParsedEvent,
  ReconnectInterval,
  createParser,
} from 'eventsource-parser'
import { OpenAIMessage } from '../openai/types'
import * as Sentry from '@sentry/nextjs'
import { useChatId } from './useChatId'

export type ChatContextProps = {
  assistantIsLoading: boolean
  setAssistantIsLoading: Dispatch<SetStateAction<boolean>>
  isUserEditing: boolean
  hasError: boolean
  initialPrompt: string
  csrfToken: string
  chat: Chat
  setChat: Dispatch<SetStateAction<Chat>>
  isAiTyping: boolean
  handleUpdateMessage: (prompt: string) => Promise<void>
  handleNewUserMessage: (prompt: string) => Promise<void>
  handleNewChat: () => void
  isInitialized: boolean
  setIsAiTyping: Dispatch<SetStateAction<boolean>>
  setInitialPrompt: Dispatch<SetStateAction<string>>
  setIsUserEditing: Dispatch<SetStateAction<boolean>>
}

const defaultChatContext = {
  assistantIsLoading: false,
  setAssistantIsLoading: () => undefined,
  isUserEditing: false,
  initialPrompt: '',
  isInitialized: false,
  csrfToken: '',
  hasError: false,
  chat: initializeChat(''),
  setChat: () => undefined,
  handleUpdateMessage: async () => undefined,
  handleNewUserMessage: async () => undefined,
  isAiTyping: false,
  handleNewChat: () => undefined,
  setIsAiTyping: () => undefined,
  setInitialPrompt: () => undefined,
  setIsUserEditing: () => undefined,
}
export const ChatContext = createContext<ChatContextProps>(defaultChatContext)

export const ChatProvider = ({ children }: PropsWithChildren) => {
  const { setIsLoading } = useContext(ScrollContext)
  const [hasError, setHasError] = useState(false)
  const isAiAnswerAdded = useRef(false)
  const csrfToken = useCSRFToken()
  const token = useChatId()
  const { t } = useTranslation()
  const [chat, setChat] = useState<Chat>({
    messages: [],
    previousProductsIds: [],
  })
  const [isAiTyping, setIsAiTyping] = useState(false)
  const [assistantIsLoading, setAssistantIsLoading] = useState(false)
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [initialPrompt, setInitialPrompt] = useState('')

  const handleNewChat = useCallback(async () => {
    sendEvent({
      event: 'Click_New_Chat',
      data: null,
    })
    await fetch('/api/chat-id')
    setAssistantIsLoading(false)
    setChat({ messages: [], previousProductsIds: [] })
    setIsAiTyping(false)
    setInitialPrompt('')
  }, [])

  const onParse = useCallback((event: ParsedEvent | ReconnectInterval) => {
    if (event.type === 'event') {
      const chunkValue = event.data

      const jsonValue = JSON.parse(chunkValue) as OpenAIMessage
      if (!isAiAnswerAdded.current) {
        setChat((prev) => addAIMessageToChat(prev, ''))
        isAiAnswerAdded.current = true
      }

      if (jsonValue.type === 'recommendation') {
        setChat((prev) => addRecommendationMessageToChat(prev, jsonValue))
      }

      if (jsonValue.type === 'text') {
        setChat((prev) => addAIMessageChunkToChat(prev, jsonValue.token))
      }

      setAssistantIsLoading(false)
    }
  }, [])

  const feed = useCallback(
    async (parser: EventSourceParser, data: ReadableStream<Uint8Array>) => {
      const reader = data.getReader()

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          break
        }
        parser.feed(new TextDecoder().decode(value))
      }
      setAssistantIsLoading(false)
      setIsAiTyping(false)
      setIsLoading(false)
      isAiAnswerAdded.current = false
    },
    [setIsLoading],
  )

  const handleUpdateMessage = useCallback(
    async (prompt: string) => {
      if (prompt === '') return

      if (csrfToken === null) {
        console.error('CSRF token is null')

        return
      }

      sendEvent({
        event: 'Send_Prompt',
        data: {
          prompt_source: PromptSource.EDITED,
          prompt_number: getChatUserLength(chat) + 1,
        },
      })

      setIsUserEditing(false)
      setAssistantIsLoading(true)
      setIsLoading(true)

      const newChat = updateMessageToChat(chat, prompt)

      setChat((prev) => updateMessageToChat(prev, prompt))
      try {
        const AImessageStream = await fetch('api/requestAnswer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'xsrf-token': csrfToken,
          },
          body: JSON.stringify({
            chat: getTextMessagesFromChat(newChat),
          }),
        })

        if (!AImessageStream.ok) {
          Sentry.captureException(AImessageStream)
          setHasError(true)
          return
        }
        const data = AImessageStream.body

        if (!data) {
          return
        }

        const parser = createParser(onParse)
        await feed(parser, data)
      } catch (error) {
        Sentry.captureException(error)
        setHasError(true)
      }
    },
    [chat, csrfToken, feed, onParse, setIsLoading],
  )

  useEffect(() => {
    if (!isAiTyping && process.env.NEXT_PUBLIC_CURRENT_ENV === 'production') {
      const sendPrompts = async (messages: TextMessage[]) => {
        for (const message of messages) {
          await fetch('api/sendPrompt', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'xsrf-token': csrfToken,
            },
            body: JSON.stringify({
              message: message.text,
              sender: message.sender,
            }),
          })
        }
      }
      const last2Messages = chat.messages
        .filter((message): message is TextMessage => message.type === 'text')
        .slice(-2)

      sendPrompts(last2Messages)
    }
  }, [chat, csrfToken, isAiTyping])

  const handleNewUserMessage = useCallback(
    async (prompt: string) => {
      setIsUserEditing(false)
      if (prompt === '') return

      if (csrfToken === null) {
        console.error('CSRF token is null')

        return
      }

      sendEvent({
        event: 'Send_Prompt',
        data: {
          prompt_source:
            prompt === t('common:first_clickable_prompt')
              ? PromptSource.EXAMPLE
              : PromptSource.TYPED,
          prompt_number: getChatUserLength(chat) + 1,
        },
      })

      setAssistantIsLoading(true)
      setIsLoading(true)
      setIsAiTyping(true)

      const newChat = addUserMessageToChat(chat, prompt)

      setChat((prev) => addUserMessageToChat(prev, prompt))

      isAiAnswerAdded.current = false
      try {
        const AImessageStream = await fetch('api/requestAnswer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'xsrf-token': csrfToken,
          },
          body: JSON.stringify({
            chat: getTextMessagesFromChat(newChat),
          }),
        })
        if (!AImessageStream.ok) {
          setHasError(true)
          return
        }
        const data = AImessageStream.body

        if (!data) {
          return
        }

        const parser = createParser(onParse)
        await feed(parser, data)
      } catch (error) {
        Sentry.captureException(error)
        setHasError(true)
      }
    },
    [chat, csrfToken, feed, onParse, setIsLoading, t],
  )

  return (
    <ChatContext.Provider
      value={{
        isInitialized: token !== null,
        assistantIsLoading,
        setAssistantIsLoading,
        isUserEditing,
        setIsUserEditing,
        initialPrompt,
        setInitialPrompt,
        setChat,
        csrfToken,
        hasError,
        chat,
        isAiTyping,
        handleUpdateMessage,
        handleNewUserMessage,
        setIsAiTyping,
        handleNewChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}
