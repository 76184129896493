var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ca8fe1d6c2ea3b41f3e046caf5ebdb7a5496623f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import packageConfig from './package.json'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const CURRENT_ENV = process.env.NEXT_PUBLIC_CURRENT_ENV
const ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  release: packageConfig.version,
  environment: CURRENT_ENV,
  enabled: ENABLED,
  hideSourceMaps: CURRENT_ENV === 'production' ? true : false,
})
