import Script from 'next/script'

export const OneTrust = () => {
  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_SCRIPT}
      />
    </>
  )
}
