import { useEffect, useState } from 'react'

/**
 * Fetches the CSRF token from the server and returns it.
 */
export const useCSRFToken = () => {
  const [token, setToken] = useState('')

  useEffect(() => {
    const fetchToken = async () => {
      const res = await fetch('/api/csrf-token')
      const data: { token: string } = await res.json()

      setToken(data.token)
    }

    fetchToken().catch((err) => {
      console.log(err)
    })
  }, [])

  return token
}
